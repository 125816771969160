@import '../../../scss/import.scss';

.emelieSetting {
  margin: pxToRem(30) pxToRem(50);

  & .emelieSetting-Attributes {
    display: flex;
    width: 50%;
    justify-content: space-between;
    padding: pxToRem(20) pxToRem(0);

    & label {
      font-size: pxToRem(18);
      font-weight: 600;
      font-family: $myFontBold;
      color: $black;
    }

    & select {
      height: 32px;
      width: 81px;
      outline: none;
    }

    & .emelieSettings-DropDown {
      display: flex;
      flex-direction: column;

      & label {
        font-size: pxToRem(14);
        font-weight: 400;
        font-family: $myFont;
        font-style: italic;
        color: map-get($gray, "A19F9D");
      }

      & .emelieSettings-flex {
        display: flex;
        flex-direction: column;
      }
    }
  }

  & .emelieSettings-btn {
    display: flex;
    margin-top: 20px;
  }
}
