.heat-exchanger-section {
    background-image: linear-gradient(90deg, transparent 50%, #d9d7d7 50%);
    background-size: 5px 8px;
    background-color: #cbcbcb;
}

.heat-exchanger-svg {
    stroke-dasharray: 10;
    animation: dash 1s infinite reverse ease-in-out;
}

.clickable-flow-button {
    width: 24px;
    height: 24px;
    position: absolute;
    text-align: center;
    padding-top: 2px;
    justify-content: center;
    border-radius: 12px;
    z-index: 100;

    &:hover {
        background-color: rgb(55, 113, 198) !important;
        cursor: pointer;
    }
}

.animate-current-step {
    padding-top: 1px;
    animation: pulse 1s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes dash {
    to {
        stroke-dashoffset: 20;
    }
}