@import "../../../scss/import.scss";

a:visited,
a:active {
  color: map-get($primary, "11387f");
}

a {
  color: map-get($primary, "11387f");
}

.active {
  background-color: map-get($white, "FAF9F8");
  color: $alfablue;
  font-family: $myFontBold;
  box-shadow: -5px 1px 2px 2px rgb(0 0 0 / 13%);
  z-index: 10;
  border-left: 4px solid $alfablue
}

.sidebar-icon {
  position: absolute;
}

.sidebar-item {
  cursor: pointer;
  text-decoration: none;
  display: flex;
  padding: 15px 20px;
  min-height: 50px;

  &:hover {
    background-color: #FAF9F8;
  }

  &:active {
    color: black;
  }

  &:visited {
    color: black;
  }
}

.sidebar-text {
  font-size: pxToRem(16);
  width: 190px;
  position: relative;
  display: block;
  margin-left: 30px;
  color: $alfablue !important;

  & a {
    text-decoration: none;
    display: flex;
  }

  &:active {
    color: $alfablue
  }

  &:visited {
    color: $alfablue
  }
}