.show {
    opacity: 1;
    visibility: visible;
    transition: all 1s;
}

.hide {
    opacity: 0;
    visibility: hidden;
    transition: all 1s;
}