@import "../../../scss/import.scss";

.emelie-breadcrumb {
    padding:pxToRem(12) 0;
    border-bottom: 1px solid #edebe9;

    & .emelie-breadcrumb--item {
        display: flex;
        list-style: none;
        padding-left: pxToRem(30);
        padding-top: pxToRem(12);

        & li {
            font-size: pxToRem(14);
            font-weight: 400;

            a {
                text-decoration: none;
                color: map-get($gray, "3B3A39");

                & .active {
                    color: map-get($primary, "0065A0");
                    font-family: "$myFont";
                    font-weight: 700;
                }
            }
        }

        & .emelie-divide {
            margin: 0 pxToRem(10);
        }
    }
 
    a{
        text-decoration: none;
    .active {
        color: map-get($primary, "0065A0");
        font-family: "$myFont";
          }
    }
   
    & .emelie-divide{
        margin: 0 pxToRem(10);
   }
}