@font-face {
  font-family: "SegoeUI";
  src: url("../fonts/segoeui.ttf");
  font-weight:600;
}
@font-face {
    font-family: "SegoeUI";
    src: url("../fonts/segoeuib.ttf");
    font-weight:900;
  }
@font-face{
  font-family: "Segoe UI Semibold";
  src:url("../fonts/seguisb.ttf");
}
@font-face{
  font-family: "Segoe UI Light";
  src:url("../fonts/segoeuil.ttf");
}

$myFont:"Segoe UI";
$myFontBold:"Segoe UI Semibold";
$myFontLight:"Segoe UI Light";