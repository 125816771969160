@import "../../../scss/function.scss";
@import "../../../scss/font.scss";
@import "../../../scss/color.scss";

.emelieBtn {
  font-size: pxToRem(16);
  outline: none;
  font-family: $myFont;
  border: #999999 solid 0px;
  cursor: pointer;
  border-radius: 5px;
  height: 38px;
  width: 130px;
  cursor: pointer;
  transition-duration: 175ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.emelie-text-btn {
  font-size: pxToRem(14);
  border: none;
  font-family: $myFont;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 5px;
  color: map-get($primary, 'dark');
  background-color: map-get($themeGray, 'background');
  transition-duration: 175ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    background-color: map-get($themeGray, 'backgroundHover');
    cursor: pointer;

    transition-duration: 175ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
}


.emelie-btn--primary {
  background-color: map-get($primary, "11387F");
  color: map-get($white, "FFFFFF");

  &:disabled {
    background-color: map-get($gray, "EDEBE9");
    color: map-get($gray, "605E5C");

    &:hover {
      cursor: auto;
    }
  }
}

.emelie-btn--white {
  background-color: map-get($white, "FFFFFF");
}

.emelieBtn--blue {
  background-color: map-get($primary, "007FC8");
  color: map-get($white, "FFFFFF");
}

.emelie-compare--btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: pxToRem(30) pxToRem(100);

  .emelie-btn--white {
    &:hover {
      background-color: map-get($gray, "EDEBE9");
      cursor: pointer;
    }

    &:disabled {
      background-color: map-get($gray, "EDEBE9");
      color: map-get($gray, "605E5C");

      &:hover {
        cursor: auto;
      }
    }
  }

  & .emelieBtn--blue {
    &:hover {
      background-color: map-get($gray, "007FC8");
      color: map-get($white, "FFFFFF");
    }

    &:disabled {
      background-color: map-get($gray, "EDEBE9");
      color: map-get($gray, "605E5C");

      &:hover {
        cursor: auto;
      }
    }
  }
}

.ms-Button--default{
  border: #999999 solid 1px;
}

.emelie-btn {
  width: 72px;
  height: 22px;
  border: none;
  outline: none;
  border-radius: 12px;
}

.emelie-check--btn {
  background-color: map-get($white, "FFFFFF");
  color: map-get($primary, "dark");

  &:hover {
    background-color: map-get($gray, "10");
    cursor: pointer;
    background-image: linear-gradient(map-get($primary, "dark"), map-get($primary, "dark"));
    background-size: 32px 1px;
  }
}

.sme-btn {
  background-color: $yellow;
  color: map-get($white, "FFFFFF");
}

.emelie-design--btn {
  display: flex;
  position: absolute;
  bottom: 0;
  margin-bottom: pxToRem(20);
}

.emelieBtn--blue:disabled,
.emelieBtn--blue[disabled] {
  border: 1px solid #999999;
  background-color: map-get($gray, "CCCCCC");
  color: map-get($gray, "666666");
}