@import './scss/font';
@import './scss/color.scss';
$emelieFont: "SegoeUI";
$emelieFontSize: 18px;
$emelieWidth: 680px;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100vh;
  overflow-y: hidden;
}

#root {
  height: 100%;
}

body {
  font-family: $emelieFont;
  font-size: $emelieFontSize;
}

code{
  background-color: #ffffff;
  border-radius: 3px;
  border: black solid 1px;
  padding: 1px 2px;
}

.container {
  width: $emelieWidth;
}

.pagination-container {
  font-size: large;
}

.pagination-container ul {
  list-style-type: none;
}

.pagination-container ul li {
  color: #11387F;

  float: left;
  padding: 5px;
  margin-left: 10px;
  cursor: pointer;
}

.pagination-container ul li:hover {
  color: #11387F;
  float: left;
  cursor: pointer;
  font-weight: bold;
}

li+.selected {
  color: #11387F;
  font-weight: bold;
  border-bottom: 3px solid #11387F;
}

.loading-spinner {
  padding: 10px;
}