@mixin color($r) {
    color: $r;
}

@mixin bgcolor($r) {
    background-color: $r;
}

$gray: (
    "323130":#323130,
    "A19F9D":#A19F9D,
    "605E5C":#605E5C,
    "CCCCCC":#CCCCCC,
    "666666":#666666,
    "EDEBE9":#EDEBE9,
    "3B3A39":#3B3A39,
    "10":#EDEBE9,
);
$black: #201F1E;
$white: (
    "FFFFFF":#FFFFFF,
    "FAF9F8":#FAF9F8,
    "white":#FFFFFF
);
$primary: (
    "007FC8":#007FC8,
    "11387F" :#11387F,
    "dark" :#11387F,
    "focused" :#11387F,
    "0065A0":#0065A0,
    "rest":#007FC8,
    "hover": #0065A0
);

$themeGray: (
    "accent":#323130,
    "background": #f5f3f2,
    "backgroundHover": #ece8e5
);

$red: #E84745;
$yellow: #FFCA42;
$alfablue: #11387F;