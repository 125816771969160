@import "../../../../scss/import.scss";

.tooltip1{
  position: relative;
  display: inline-block;
 
& .emelie-design--tooltip-1{
  position: absolute;
  left:180px;
  top: 30px;
}

& .tooltiptext {
  position: absolute;
  z-index: 1;
  top: -75px;
  margin-left:pxToRem(120);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);

  & .tooltiptext--content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left:pxToREm(48);

    & .tooltiptext--title{
      color: map-get($primary, "0065A0");
      font-size:pxToRem(18);
      font-family: $myFontBold;
    }
    & .tooltiptext--para{
     font-size:pxToRem(14);
     color: map-get($gray, "323130");
      text-align: left;
      padding-right: pxToRem(20);
    }
  }
}
}
.tooltip1:hover .tooltiptext {
  visibility: visible;
}