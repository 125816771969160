@import "../scss/import.scss";

.emelie-container {
  padding-left: 2.2%;
  padding-right: 2.2%;
}

.emelie-width {
  width: 100%;
}

.emelie-width-50 {
  width: 50%;
}

.e-width {
  width: pxToRem(300);
}

.emelie-height {
  height: 100%;
}

.e-height {
  height: pxToRem(120);
}

.h-40 {
  height: 40px !important;
}

.emelie-border-bottom {
  border-bottom: 1px solid #E1DFDD;
}

// flex

.emelie-flex {
  display: flex;
  width: 100%;
  height: 100%;
}

.e-flex {
  display: flex;
}

.emelie-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.emelie-flex-col {
  flex-direction: column;
}

.emelie-justify-sb {
  justify-content: space-between;
}

.emelie-justify-fs {
  justify-content: flex-start;
}

.emelie--template {
  height: calc(100% - 80px);
}

.e-p-30 {
  padding: pxToRem(30);
}

.e-ml-50 {
  margin-left: 50px;
}

.e-pb-70 {
  padding-bottom: 70px;
}

// emelie-headings
.emelie-f-32 {
  font-size: pxToRem(32);
  font-family: $myFontLight;
  color: map-get($primary, "0065A0");
}

.emelie-f-28 {
  font-size: pxToRem(28);
  font-family: $myFontBold;
  color: map-get($gray, "323130");
}

.emelie-f-24 {
  font-size: pxToRem(24);
  font-family: $myFontBold;
  color: map-get($gray, "323130");
}

.emelie-f-24--primary {
  font-size: pxToRem(24);
  font-family: $myFontBold;
  color: map-get($primary, "11387F");
}

.emelie-f-24--blue {
  font-size: pxToRem(24);
  font-family: $myFontBold;
  color: map-get($primary, "0065A0");
}

.emelie-f-24--regular {
  font-size: pxToRem(24);
  font-family: $myFont;
  color: map-get($gray, "323130");
}

.emelie-f-20 {
  font-size: pxToRem(20);
  font-family: $myFont;
  color: map-get($gray, "323130");
}

.emelie-f-18 {
  font-size: pxToRem(18);
  font-family: $myFont;
  color: map-get($gray, "323130");
}

.emelie-f-18-black {
  font-size: pxToRem(18);
  font-family: $myFont;
  color: map-get($gray, "605E5C");
}

.emelie-f-16 {
  font-size: pxToRem(16);
  font-family: $myFont;
  color: map-get($gray, "605E5C");
}

.emelie-f-16--primary {
  font-size: pxToRem(16);
  font-family: $myFont;
  color: map-get($primary, "11387F");
}

.emelie-f-12 {
  font-size: pxToRem(12);
  color: $red;
}

//padding
.e-p-10 {
  padding: pxToRem(10);
}

.e-p-15 {
  padding: pxToRem(15);
}

.e-p-30 {
  padding: pxToRem(30);
}

// padding-top
.e-pt-10 {
  padding-top: pxToRem(10);
}

.e-pt-16 {
  padding-top: pxToRem(16);
}

.e-pt-25 {
  padding-top: pxToRem(25);
}

// padding-bottom
.e-pb-10 {
  padding-bottom: pxToRem(10);
}

.e-pb-70 {
  padding-bottom: pxToRem(70px);
}

// padding-right
.e-pr-25 {
  padding-right: pxToRem(25);
}

// padding-left
.e-pl-16 {
  padding-left: pxToRem(16);
}

.e-pl-24 {
  padding-left: pxToRem(24);
}

.e-pl-30 {
  padding: pxToRem(30);
}

.e-pl-50 {
  padding: pxToRem(50);
}

// margin
.e-m-30 {
  margin: pxToRem(30);
}

// margin-top
.e-mt-10 {
  margin-top: pxToRem(10);
}

.e-mt-15 {
  margin-top: pxToRem(15);
}

.e-mt-30 {
  margin-top: pxToRem(30);
}

.e-mt-60 {
  margin-top: pxToRem(60);
}

// margin-right
.e-mr-15 {
  margin-right: pxToRem(15);
}

.e-mr-30 {
  margin-right: pxToRem(30);
}

.e-mr-50 {
  margin-right: pxToRem(50);
}

// margin-left
.e-ml-16 {
  margin-left: pxToRem(16);
}

.e-ml-30 {
  margin-left: pxToRem(30);
}

.e-ml-50 {
  margin-left: pxToRem(50);
}

// margin-bottom
.e-mb-10 {
  margin-bottom: pxToRem(10);
}

.e-mb-30 {
  margin-bottom: pxToRem(30);
}

// position
.e-absolute {
  position: absolute;
}

.e-relative {
  position: relative;
}

// color
.e-bg-white {
  background-color: map-get($white, "FFFFFF");
}

// border
.e-border {
  border: 1px solid #EDEBE9;
}

.e-border-gray {
  border: 1px solid #D2D0CE;
}

//radio

.emelie-radio {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 500px;
  height: 144px;
  background-color: map-get($white, "FFFFFF");
  border: 1px solid #E1DFDD;
  border-radius: 10px;
  margin-right: pxToRem(50);
  margin-top: pxToRem(25);

  & .emelie-radio--input {
    margin-right: pxToRem(35);
    height: 16px;
    width: 16px;
  }
}

// input

.styledInput {
  //   height: 40px;
  //   width: 329px;
  //   outline: none;
  //   border: none;
  //   border: 1px solid #605E5C;
  //   padding-left: pxToRem(10);  
}

.ms-TextField-fieldGroup {
  height: auto;
}

input {
  height: 40px;
  padding-bottom: 5px;
  font-size: large;
  outline: none;
  border: none;
  border: 1px solid #605E5C;
  //   padding-left: pxToRem(10);
}

textarea {
  resize: none;
  outline: none;
  overflow-x: auto;
  font-family: $myFont;
  font-size: pxToRem(16);
  color: map-get($gray, "605e5c");
  padding: pxToRem(10);
  height: 184px;
  width: 329px;
  margin-top: pxToRem(10);
}

::placeholder {
  font-family: $myFont;
  font-size: pxToRem(16);
}

.emelie-filter input {
  height: 40px;
  width: 150px;
  outline: none;
  border: none;
  border: 1px solid #605E5C;
}

.emelie-input {
  max-width: 330px;
  background: #FFFFFF;
  padding-left: pxToRem(10px);
  overflow: hidden;
  border: 1px solid #605E5C;
}

.emelie-input input {
  flex-grow: 1;
  font-size: pxToRem(14);
  background: #FFFFFF;
  border: none;
  outline: none;
  padding: pxToRem(10);
}

.emelie-input-units {
  font-size: pxToRem(16);
  color: map-get($gray, "323130");
  background: rgb(243, 242, 241);
  height: 100%;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emelie-input-title {
  margin-bottom: pxToRem(5);
  font-size: pxToRem(16);
  color: map-get($gray, "323130");
}

.emelie-design--vl {
  border: 1px solid #e1dfdd;
}

.emelie-alfaLaval-img {
  margin: pxToRem(60) 0;
  width: 60%;
}

// tooltip
.emelie-tooltip {
  font-family: $myFontBold;
  color: map-get($white, "FFFFFF");
  background-color: map-get($primary, "0065A0");
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.emelie-tooltip--property {
  visibility: hidden;
  width: 661px;
  height: 202px;
  background-color: map-get($white, "FFFFFF");
  color: map-get($white, "FFFFFF");
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.column {
  float: left;
  width: 50%;
  padding: 10px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

h1 {
  font-size: pxToRem(32);
  font-family: $myFontLight;
  color: map-get($primary, "0065A0");
}

h2 {
  font-size: pxToRem(28);
  font-family: $myFontLight;
  color: map-get($primary, "0065A0");
}

h3 {
  font-size: pxToRem(24);
  font-family: $myFontLight;
  color: map-get($primary, "0065A0");
}

input {
  font-weight: 500 !important;
}

.ms-Button {
  border-radius: 5px !important;
}


.flex {
  display: flex;
}

.flex {
  &h {
    flex-direction: row;
  }

  &v {
    flex-direction: column;
  }

  &-hevenly {
    justify-content: space-between;
  }
}