@import "../../../scss/import.scss";

.emelie-dashboard {
  padding: 30px;
  width: auto;

  & .emelie-dashboard--design {
    width: 100%;
    background-color: map-get($white, "FFFFFF");
    display: flex;
    justify-content: space-between;
    margin-top: pxToRem(10);
    padding: 0 pxToRem(30);
  }
  .emelie-dashboard--content {
    width: 100%;
    padding-top: pxToRem(10);
    height: 150px;
  }
  .emelie-dasboard--img {
    margin-top: -50px;
  }
}
