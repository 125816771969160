.emelie-body {
  height: calc(100vh - 60px);
  overflow: auto;
  position: relative;
  width: 100%;
}

.unauthorized {
  padding: 40px;
  text-align: center;
}

.left-border-blue {
  // border-left: 3px solid #11387F;
  border: 10px solid;
  border-image-slice: 1;
  border-width: 3px;
  border-image-source: linear-gradient(to left, #add9ff, #308cdd, #11387F);
}

.todo-highlight {
  border: 2px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #add9ff, #308cdd, #11387F);
  background: linear-gradient(270deg, #e8f6ff, #fff);
}