@import "../../../../scss/font.scss";
@import "../../../../scss/color.scss";
@import "../../../../scss/global.scss";

.tooltip1 {
  position: relative;
  display: inline-block;

  & .emelie-design--tooltip-2 {
    position: absolute;
    left: 415px;
    top: 180px;
  }

  & .tooltiptext {
    position: absolute;
    z-index: 1;
    top: -75px;
    left: 250px;
  }
}
