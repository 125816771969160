@import "../../../scss/import.scss";

.emelie-navbar {
  height: calc(100% - 88px);
  display: flex;
  height: 64px;
  justify-content: space-between;
  align-items: center;
  background-color: map-get($white, "FFFFFF");
  border-bottom: 1px solid #e1dfdd;

  & .emelie-navbar--logomark {
    display: flex;
  }

  & .emelie-navbar--logotext {
    display: flex;
}
}