@import "../../../scss/import.scss";

.tooltip {
  position: relative;
  display: inline-block;
  margin-left: pxToRem(15);

  & .tooltiptext {
    visibility: hidden;
    font-size: pxToRem(13);
    background-color: #ffffff;
    color: map-get($gray, "323130");
    text-align: center;
    border-radius: 6px;
    padding: pxToRem(5);
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -130px;
    margin-bottom: 10px;
    font-family: $myFont;
    width:250px;
  }
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  box-shadow: 0 6.7px 15px 0 rgba(0, 0, 0, 0.13);
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: pxtoRem(-5);
  border-width: pxToRem(5);
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
}